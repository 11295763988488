import {
  createApp
} from 'vue'
import App from '@/App.vue'

import {
  registerPlugins
} from '@core/utils/plugins'

// Styles
import '@core/scss/template/index.scss'
import '@styles/styles.scss'

import * as Sentry from "@sentry/vue";


// Create vue app
const app = createApp(App)

Sentry.init({
  app,
  dsn: "https://0bc9687df4cd3328876bf776da382947@o4508040967487488.ingest.de.sentry.io/4508331469570128",
  integrations: [
    // Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// Register plugins
registerPlugins(app)

// Mount vue app
app.mount('#app')