{
  "UI Elements": "عناصر واجهة المستخدم",
  "Forms & Tables": "النماذج والجداول",
  "Pages": "الصفحات",
  "Charts & Maps": "الرسوم البيانية والخرائط",
  "Others": "آحرون",
  "Typography": "الطباعة",
  "Cards": "البطاقات",
  "Basic": "أساسي",
  "Advance": "يتقدم",
  "Analytic": "تحليلي",
  "Actions": "أجراءات",
  "Components": "عناصر",
  "Alert": "انذار",
  "Close Alert": "أغلق التنبيه",
  "Avatar": "الصورة الرمزية",
  "Badge": "شارة",
  "Button": "زر",
  "Calendar": "تقويم",
  "Image": "صورة",
  "Pagination": "ترقيم الصفحات",
  "Progress Circular": "تقدم التعميم",
  "Progress Linear": "تقدم خطي",
  "Autocomplete": "الإكمال التلقائي",
  "Tooltip": "تلميح",
  "Slider": "المنزلق",
  "Date Time Picker": "منتقي التاريخ والوقت",
  "Select": "يختار",
  "Switch": "يُحوّل",
  "Checkbox": "خانة اختيار",
  "Radio": "مذياع",
  "Textarea": "تيكستاريا",
  "Rating": "تقييم",
  "File Input": "إدخال الملف",
  "Otp Input": "إدخال أوتب",
  "Form Layout": "تخطيط النموذج",
  "Form Validation": "التحقق من صحة النموذج",
  "Charts": "الرسوم البيانية",
  "Apex Chart": "مخطط أبيكس",
  "Chartjs": "تشارتجس",
  "Account Settings": "إعدادت الحساب",
  "User Profile": "ملف تعريفي للمستخدم",
  "FAQ": "التعليمات",
  "Dialog Examples": "أمثلة على الحوار",
  "Pricing": "التسعير",
  "List": "قائمة",
  "Edit": "يحرر",
  "Nav Levels": "مستويات التنقل",
  "Level 2.1": "المستوى 2.1",
  "Level 2.2": "مستوى 2.2",
  "Level 3.1": "المستوى 3.1",
  "Level 3.2": "المستوى 3.2",
  "Raise Support": "رفع الدعم",
  "Documentation": "توثيق",
  "Dashboards": "لوحات القيادة",
  "Apps & Pages": "التطبيقات والصفحات",
  "Email": "البريد الإلكتروني",
  "Chat": "دردشة",
  "Invoice": "فاتورة",
  "Preview": "معاينة",
  "Add": "يضيف",
  "User": "المستعمل",
  "View": "رأي",
  "Login v1": "تسجيل الدخول v1",
  "Login v2": "تسجيل الدخول v2",
  "Login": "تسجيل الدخول",
  "Register v1": "تسجيل v1",
  "Register v2": "تسجيل v2",
  "Register": "تسجيل",
  "Forget Password v1": "نسيت كلمة المرور v1",
  "Forget Password v2": "نسيت كلمة المرور v2",
  "Forgot Password v1": "نسيت كلمة المرور v1",
  "Forgot Password v2": "نسيت كلمة المرور v2",
  "Forgot Password": "نسيت كلمة المرور",
  "Reset Password v1": "إعادة تعيين كلمة المرور v1",
  "Reset Password v2": "إعادة تعيين كلمة المرور v2",
  "Reset Password": "إعادة تعيين كلمة المرور",
  "Miscellaneous": "متفرقات",
  "Coming Soon": "قريبا",
  "Not Authorized": "غير مخول",
  "Under Maintenance": "تحت الصيانة",
  "Error": "خطأ",
  "Statistics": "إحصائيات",
  "Analytics": "تحليلات",
  "Access Control": "صلاحية التحكم صلاحية الدخول",
  "User Interface": "واجهة المستخدم",
  "CRM": "سي آر إم",
  "Icons": "أيقونات",
  "Chip": "رقاقة",
  "Dialog": "حوار",
  "Expansion Panel": "لوحة التوسع",
  "Combobox": "صندوق التحرير",
  "Textfield": "مجال التحرير مكان كتابة النص",
  "Range Slider": "نطاق المنزلق",
  "Menu": "قائمة الطعام",
  "Snackbar": "مطعم الوجبات الخفيفة",
  "Tabs": "نوافذ التبويب",
  "Form Elements": "عناصر النموذج",
  "Form Layouts": "تخطيطات النموذج",
  "Authentication": "المصادقة",
  "Page Not Found - 404": "الصفحة غير موجودة - 404",
  "Not Authorized - 401": "غير مصرح - 401",
  "Server Error - 500": "خطأ في الخادم - 500",
  "2": "2",
  "Gamification": "التلعيب",
  "Forms": "نماذج",
  "Timeline": "الجدول الزمني",
  "Disabled Menu": "قائمة المعوقين",
  "Help Center": "مركز المساعدة",
  "Verify Email": "التحقق من البريد الإلكتروني",
  "Verify Email v1": "تحقق من البريد الإلكتروني v1",
  "Verify Email v2": "تحقق من البريد الإلكتروني v2",
  "Two Steps": "خطوتين",
  "Two Steps v1": "خطوتين v1.0",
  "Two Steps v2": "خطوتين v2.0",
  "Custom Input": "إدخال مخصص",
  "Extensions": "ملحقات",
  "Tour": "رحلة",
  "Register Multi-Steps": "تسجيل خطوات متعددة",
  "Wizard Examples": "أمثلة المعالج",
  "Checkout": "الدفع",
  "Create Deal": "إنشاء صفقة",
  "Property Listing": "قائمة الممتلكات ",
  "Roles & Permissions": "الأدوار والأذونات",
  "Roles": "الأدوار",
  "Permissions": "الأذونات",
  "Simple Table": "جدول بسيط",
  "Tables": "الجداول",
  "DataTable": "جدول البيانات",
  "Data Table": "جدول البيانات",
  "Apps": "التطبيقات",
  "Misc": "متفرقات",
  "Wizard Pages": "صفحات المعالج",
  "Form Wizard": "معالج النموذج",
  "Numbered": "مرقم",
  "eCommerce": "التجارة الإلكترونية",
  "Ecommerce": "التجارة الإلكترونية",
  "Product": "المنتج",
  "Category": "الفئة",
  "Order": "طلب",
  "Details": "تفاصيل",
  "Customer": "الزبون",
  "Manage Review": "إدارة المراجعة",
  "Referrals": "الإحالات",
  "Settings": "الإعدادات",
  "Course Details": "تفاصيل الدورة التدريبية",
  "My Course": "دورتي",
  "Overview": "نظرة عامة",
  "Academy": "أكاديمية",
  "Logistics": "الخدمات اللوجستية",
  "Dashboard": "لوحة القيادة",
  "Fleet": "الأسطول",
  "Editors": "المحررين",
  "Front Pages": "الصفحات الأمامية",
  "Landing": "المقصودة",
  "checkout": "الدفع",
  "Payment": "دفع",
  "Swiper": "المنزلق",
  "Widgets": "الحاجيات",
  "5": "5",
  "10": "10",
  "20": "20",
  "25": "25",
  "50": "50",
  "100": "100",
  "$vuetify": {
    "badge": "شارة",
    "noDataText": "لا تتوافر بيانات",
    "close": "قريب",
    "open": "افتح",
    "dataFooter": {
      "itemsPerPageText": "مواد لكل صفحة:",
      "itemsPerPageAll": "الجميع",
      "pageText": "{0} - {1} من {2}",
      "firstPage": "الصفحة الأولى",
      "prevPage": "الصفحة السابقة",
      "nextPage": "الصفحة التالية",
      "lastPage": "آخر صفحة"
    },
    "pagination": {
      "ariaLabel": {
        "root": "جذر",
        "previous": "السابق",
        "next": "التالي",
        "currentPage": "الصفحه الحاليه",
        "page": "صفحة",
        "first": "الصفحة الأولى",
        "last": "آخر صفحة"
      }
    },
    "input": {
      "clear": "صافي",
      "appendAction": "إلحاق الإجراء",
      "prependAction": "قبل العمل",
      "otp": "أوتب"
    },
    "fileInput": {
      "counterSize": "حجم العداد"
    },
    "rating": {
      "ariaLabel": {
        "item": "العنصر"
      }
    }
  }
}
