import _definePage_default_0 from 'C:/Users/Usuario/Documents/GitHub/catona/src/pages/[...error].vue?definePage&vue'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from 'C:/Users/Usuario/Documents/GitHub/catona/src/pages/access-control.vue?definePage&vue'
import _definePage_default_3 from 'C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/chat.vue?definePage&vue'
import _definePage_default_4 from 'C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/email/index.vue?definePage&vue'
import _definePage_default_5 from 'C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/logistics/fleet.vue?definePage&vue'
import _definePage_default_6 from 'C:/Users/Usuario/Documents/GitHub/catona/src/pages/forgot-password.vue?definePage&vue'
import _definePage_default_7 from 'C:/Users/Usuario/Documents/GitHub/catona/src/pages/front-pages/checkout.vue?definePage&vue'
import _definePage_default_8 from 'C:/Users/Usuario/Documents/GitHub/catona/src/pages/front-pages/help-center/index.vue?definePage&vue'
import _definePage_default_9 from 'C:/Users/Usuario/Documents/GitHub/catona/src/pages/front-pages/help-center/article/[title].vue?definePage&vue'
import _definePage_default_10 from 'C:/Users/Usuario/Documents/GitHub/catona/src/pages/front-pages/landing-page/index.vue?definePage&vue'
import _definePage_default_11 from 'C:/Users/Usuario/Documents/GitHub/catona/src/pages/front-pages/payment.vue?definePage&vue'
import _definePage_default_12 from 'C:/Users/Usuario/Documents/GitHub/catona/src/pages/front-pages/pricing.vue?definePage&vue'
import _definePage_default_13 from 'C:/Users/Usuario/Documents/GitHub/catona/src/pages/login.vue?definePage&vue'
import _definePage_default_14 from 'C:/Users/Usuario/Documents/GitHub/catona/src/pages/not-authorized.vue?definePage&vue'
import _definePage_default_15 from 'C:/Users/Usuario/Documents/GitHub/catona/src/pages/pages/account-settings/[tab].vue?definePage&vue'
import _definePage_default_16 from 'C:/Users/Usuario/Documents/GitHub/catona/src/pages/pages/authentication/forgot-password-v1.vue?definePage&vue'
import _definePage_default_17 from 'C:/Users/Usuario/Documents/GitHub/catona/src/pages/pages/authentication/forgot-password-v2.vue?definePage&vue'
import _definePage_default_18 from 'C:/Users/Usuario/Documents/GitHub/catona/src/pages/pages/authentication/login-v1.vue?definePage&vue'
import _definePage_default_19 from 'C:/Users/Usuario/Documents/GitHub/catona/src/pages/pages/authentication/login-v2.vue?definePage&vue'
import _definePage_default_20 from 'C:/Users/Usuario/Documents/GitHub/catona/src/pages/pages/authentication/register-multi-steps.vue?definePage&vue'
import _definePage_default_21 from 'C:/Users/Usuario/Documents/GitHub/catona/src/pages/pages/authentication/register-v1.vue?definePage&vue'
import _definePage_default_22 from 'C:/Users/Usuario/Documents/GitHub/catona/src/pages/pages/authentication/register-v2.vue?definePage&vue'
import _definePage_default_23 from 'C:/Users/Usuario/Documents/GitHub/catona/src/pages/pages/authentication/reset-password-v1.vue?definePage&vue'
import _definePage_default_24 from 'C:/Users/Usuario/Documents/GitHub/catona/src/pages/pages/authentication/reset-password-v2.vue?definePage&vue'
import _definePage_default_25 from 'C:/Users/Usuario/Documents/GitHub/catona/src/pages/pages/authentication/two-steps-v1.vue?definePage&vue'
import _definePage_default_26 from 'C:/Users/Usuario/Documents/GitHub/catona/src/pages/pages/authentication/two-steps-v2.vue?definePage&vue'
import _definePage_default_27 from 'C:/Users/Usuario/Documents/GitHub/catona/src/pages/pages/authentication/verify-email-v1.vue?definePage&vue'
import _definePage_default_28 from 'C:/Users/Usuario/Documents/GitHub/catona/src/pages/pages/authentication/verify-email-v2.vue?definePage&vue'
import _definePage_default_29 from 'C:/Users/Usuario/Documents/GitHub/catona/src/pages/pages/misc/coming-soon.vue?definePage&vue'
import _definePage_default_30 from 'C:/Users/Usuario/Documents/GitHub/catona/src/pages/pages/misc/under-maintenance.vue?definePage&vue'
import _definePage_default_31 from 'C:/Users/Usuario/Documents/GitHub/catona/src/pages/pages/user-profile/[tab].vue?definePage&vue'
import _definePage_default_32 from 'C:/Users/Usuario/Documents/GitHub/catona/src/pages/register.vue?definePage&vue'
import _definePage_default_33 from 'C:/Users/Usuario/Documents/GitHub/catona/src/pages/reset-password.vue?definePage&vue'

export const routes = [
  _mergeRouteRecord(
  {
    path: '/:error(.*)',
    name: '$error',
    component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/[...error].vue'),
    /* no children */
  },
  _definePage_default_0
  ),
  _mergeRouteRecord(
  {
    path: '/access-control',
    name: 'access-control',
    component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/access-control.vue'),
    /* no children */
  },
  _definePage_default_2
  ),
  {
    path: '/apps',
    /* internal name: 'apps' */
    /* no component */
    children: [
      {
        path: 'academy',
        /* internal name: 'apps-academy' */
        /* no component */
        children: [
          {
            path: 'course-details',
            name: 'apps-academy-course-details',
            component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/academy/course-details.vue'),
            /* no children */
          },
          {
            path: 'dashboard',
            name: 'apps-academy-dashboard',
            component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/academy/dashboard.vue'),
            /* no children */
          },
          {
            path: 'my-course',
            name: 'apps-academy-my-course',
            component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/academy/my-course.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'booking',
        /* internal name: 'apps-booking' */
        /* no component */
        children: [
          {
            path: 'list',
            /* internal name: 'apps-booking-list' */
            /* no component */
            children: [
              {
                path: '',
                name: 'apps-booking-list',
                component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/booking/list/index.vue'),
                /* no children */
              },
              {
                path: 'Asistencia',
                name: 'apps-booking-list-asistencia',
                component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/booking/list/Asistencia.vue'),
                /* no children */
              },
              {
                path: 'bookingDrawer',
                name: 'apps-booking-list-booking-drawer',
                component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/booking/list/bookingDrawer.vue'),
                /* no children */
              },
              {
                path: 'BookingHistoryDialog',
                name: 'apps-booking-list-booking-history-dialog',
                component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/booking/list/BookingHistoryDialog.vue'),
                /* no children */
              },
              {
                path: 'CancelDialog',
                name: 'apps-booking-list-cancel-dialog',
                component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/booking/list/CancelDialog.vue'),
                /* no children */
              }
            ],
          }
        ],
      },
      {
        path: 'calendar',
        name: 'apps-calendar',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/calendar.vue'),
        /* no children */
      },
  _mergeRouteRecord(
      {
        path: 'chat',
        name: 'apps-chat',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/chat.vue'),
        /* no children */
      },
  _definePage_default_3
  ),
      {
        path: 'config',
        /* internal name: 'apps-config' */
        /* no component */
        children: [
          {
            path: 'day',
            /* internal name: 'apps-config-day' */
            /* no component */
            children: [
              {
                path: '',
                name: 'apps-config-day',
                component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/config/day/index.vue'),
                /* no children */
              },
              {
                path: 'FechaDrawer',
                name: 'apps-config-day-fecha-drawer',
                component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/config/day/FechaDrawer.vue'),
                /* no children */
              },
              {
                path: 'FechaTab',
                name: 'apps-config-day-fecha-tab',
                component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/config/day/FechaTab.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'email',
            /* internal name: 'apps-config-email' */
            /* no component */
            children: [
              {
                path: '',
                name: 'apps-config-email',
                component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/config/email/index.vue'),
                /* no children */
              },
              {
                path: 'Editor',
                name: 'apps-config-email-editor',
                component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/config/email/Editor.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'general',
            /* internal name: 'apps-config-general' */
            /* no component */
            children: [
              {
                path: '',
                name: 'apps-config-general',
                component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/config/general/index.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'hours',
            /* internal name: 'apps-config-hours' */
            /* no component */
            children: [
              {
                path: '',
                name: 'apps-config-hours',
                component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/config/hours/index.vue'),
                /* no children */
              },
              {
                path: 'hourDrawer',
                name: 'apps-config-hours-hour-drawer',
                component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/config/hours/hourDrawer.vue'),
                /* no children */
              },
              {
                path: 'SpecialHourDrawer',
                name: 'apps-config-hours-special-hour-drawer',
                component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/config/hours/SpecialHourDrawer.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'menu',
            /* internal name: 'apps-config-menu' */
            /* no component */
            children: [
              {
                path: '',
                name: 'apps-config-menu',
                component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/config/menu/index.vue'),
                /* no children */
              },
              {
                path: 'menuDrawer',
                name: 'apps-config-menu-menu-drawer',
                component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/config/menu/menuDrawer.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'salon',
            /* internal name: 'apps-config-salon' */
            /* no component */
            children: [
              {
                path: '',
                name: 'apps-config-salon',
                component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/config/salon/index.vue'),
                /* no children */
              },
              {
                path: 'salonDrawer',
                name: 'apps-config-salon-salon-drawer',
                component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/config/salon/salonDrawer.vue'),
                /* no children */
              }
            ],
          }
        ],
      },
      {
        path: 'customer',
        /* internal name: 'apps-customer' */
        /* no component */
        children: [
          {
            path: 'list',
            /* internal name: 'apps-customer-list' */
            /* no component */
            children: [
              {
                path: '',
                name: 'apps-customer-list',
                component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/customer/list/index.vue'),
                /* no children */
              },
              {
                path: 'clienteDrawer',
                name: 'apps-customer-list-cliente-drawer',
                component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/customer/list/clienteDrawer.vue'),
                /* no children */
              }
            ],
          }
        ],
      },
      {
        path: 'ecommerce',
        /* internal name: 'apps-ecommerce' */
        /* no component */
        children: [
          {
            path: 'customer',
            /* internal name: 'apps-ecommerce-customer' */
            /* no component */
            children: [
              {
                path: 'details',
                /* internal name: 'apps-ecommerce-customer-details' */
                /* no component */
                children: [
                  {
                    path: ':id',
                    name: 'apps-ecommerce-customer-details-id',
                    component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/ecommerce/customer/details/[id].vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'list',
                /* internal name: 'apps-ecommerce-customer-list' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'apps-ecommerce-customer-list',
                    component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/ecommerce/customer/list/index.vue'),
                    /* no children */
                  }
                ],
              }
            ],
          },
          {
            path: 'manage-review',
            name: 'apps-ecommerce-manage-review',
            component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/ecommerce/manage-review.vue'),
            /* no children */
          },
          {
            path: 'order',
            /* internal name: 'apps-ecommerce-order' */
            /* no component */
            children: [
              {
                path: 'details',
                /* internal name: 'apps-ecommerce-order-details' */
                /* no component */
                children: [
                  {
                    path: ':id',
                    name: 'apps-ecommerce-order-details-id',
                    component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/ecommerce/order/details/[id].vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'list',
                /* internal name: 'apps-ecommerce-order-list' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'apps-ecommerce-order-list',
                    component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/ecommerce/order/list/index.vue'),
                    /* no children */
                  }
                ],
              }
            ],
          },
          {
            path: 'product',
            /* internal name: 'apps-ecommerce-product' */
            /* no component */
            children: [
              {
                path: 'add',
                /* internal name: 'apps-ecommerce-product-add' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'apps-ecommerce-product-add',
                    component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/ecommerce/product/add/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'category-list',
                name: 'apps-ecommerce-product-category-list',
                component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/ecommerce/product/category-list.vue'),
                /* no children */
              },
              {
                path: 'list',
                /* internal name: 'apps-ecommerce-product-list' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'apps-ecommerce-product-list',
                    component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/ecommerce/product/list/index.vue'),
                    /* no children */
                  }
                ],
              }
            ],
          },
          {
            path: 'referrals',
            name: 'apps-ecommerce-referrals',
            component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/ecommerce/referrals.vue'),
            /* no children */
          },
          {
            path: 'settings',
            name: 'apps-ecommerce-settings',
            component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/ecommerce/settings.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'email',
        /* internal name: 'apps-email' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'apps-email',
            component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/email/index.vue'),
            /* no children */
          },
  _definePage_default_4
  )
        ],
      },
      {
        path: 'incidence',
        /* internal name: 'apps-incidence' */
        /* no component */
        children: [
          {
            path: 'list',
            /* internal name: 'apps-incidence-list' */
            /* no component */
            children: [
              {
                path: '',
                name: 'apps-incidence-list',
                component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/incidence/list/index.vue'),
                /* no children */
              }
            ],
          }
        ],
      },
      {
        path: 'invoice',
        /* internal name: 'apps-invoice' */
        /* no component */
        children: [
          {
            path: 'add',
            /* internal name: 'apps-invoice-add' */
            /* no component */
            children: [
              {
                path: '',
                name: 'apps-invoice-add',
                component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/invoice/add/index.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'edit',
            /* internal name: 'apps-invoice-edit' */
            /* no component */
            children: [
              {
                path: ':id',
                name: 'apps-invoice-edit-id',
                component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/invoice/edit/[id].vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'list',
            /* internal name: 'apps-invoice-list' */
            /* no component */
            children: [
              {
                path: '',
                name: 'apps-invoice-list',
                component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/invoice/list/index.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'preview',
            /* internal name: 'apps-invoice-preview' */
            /* no component */
            children: [
              {
                path: ':id',
                name: 'apps-invoice-preview-id',
                component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/invoice/preview/[id].vue'),
                /* no children */
              }
            ],
          }
        ],
      },
      {
        path: 'logistics',
        /* internal name: 'apps-logistics' */
        /* no component */
        children: [
          {
            path: 'dashboard',
            name: 'apps-logistics-dashboard',
            component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/logistics/dashboard.vue'),
            /* no children */
          },
  _mergeRouteRecord(
          {
            path: 'fleet',
            name: 'apps-logistics-fleet',
            component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/logistics/fleet.vue'),
            /* no children */
          },
  _definePage_default_5
  )
        ],
      },
      {
        path: 'logs',
        /* internal name: 'apps-logs' */
        /* no component */
        children: [
          {
            path: 'list',
            /* internal name: 'apps-logs-list' */
            /* no component */
            children: [
              {
                path: '',
                name: 'apps-logs-list',
                component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/logs/list/index.vue'),
                /* no children */
              },
              {
                path: 'logDetail',
                name: 'apps-logs-list-log-detail',
                component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/logs/list/logDetail.vue'),
                /* no children */
              }
            ],
          }
        ],
      },
      {
        path: 'permissions',
        /* internal name: 'apps-permissions' */
        /* no component */
        children: [
          {
            path: '',
            name: 'apps-permissions',
            component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/permissions/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'promotions',
        /* internal name: 'apps-promotions' */
        /* no component */
        children: [
          {
            path: 'list',
            /* internal name: 'apps-promotions-list' */
            /* no component */
            children: [
              {
                path: '',
                name: 'apps-promotions-list',
                component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/promotions/list/index.vue'),
                /* no children */
              },
              {
                path: 'Editor',
                name: 'apps-promotions-list-editor',
                component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/promotions/list/Editor.vue'),
                /* no children */
              },
              {
                path: 'promocionModal',
                name: 'apps-promotions-list-promocion-modal',
                component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/promotions/list/promocionModal.vue'),
                /* no children */
              }
            ],
          }
        ],
      },
      {
        path: 'room',
        /* internal name: 'apps-room' */
        /* no component */
        children: [
          {
            path: 'list',
            /* internal name: 'apps-room-list' */
            /* no component */
            children: [
              {
                path: '',
                name: 'apps-room-list',
                component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/room/list/index.vue'),
                /* no children */
              },
              {
                path: 'bookingsDetail',
                name: 'apps-room-list-bookings-detail',
                component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/room/list/bookingsDetail.vue'),
                /* no children */
              }
            ],
          }
        ],
      },
      {
        path: 'signing',
        /* internal name: 'apps-signing' */
        /* no component */
        children: [
          {
            path: 'list',
            /* internal name: 'apps-signing-list' */
            /* no component */
            children: [
              {
                path: '',
                name: 'apps-signing-list',
                component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/signing/list/index.vue'),
                /* no children */
              }
            ],
          }
        ],
      },
      {
        path: 'user',
        /* internal name: 'apps-user' */
        /* no component */
        children: [
          {
            path: 'list',
            /* internal name: 'apps-user-list' */
            /* no component */
            children: [
              {
                path: '',
                name: 'apps-user-list',
                component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/user/list/index.vue'),
                /* no children */
              },
              {
                path: 'UserDrawer',
                name: 'apps-user-list-user-drawer',
                component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/user/list/UserDrawer.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'view',
            /* internal name: 'apps-user-view' */
            /* no component */
            children: [
              {
                path: ':id',
                name: 'apps-user-view-id',
                component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/apps/user/view/[id].vue'),
                /* no children */
              }
            ],
          }
        ],
      }
    ],
  },
  {
    path: '/apps/email/:filter',
    name: 'apps-email-filter',
    component: () => import('/src/pages/apps/email/index.vue'),
    /* no children */
  },
  {
    path: '/apps/email/:label',
    name: 'apps-email-label',
    component: () => import('/src/pages/apps/email/index.vue'),
    /* no children */
  },
  {
    path: '/charts',
    /* internal name: 'charts' */
    /* no component */
    children: [
      {
        path: 'apex-chart',
        name: 'charts-apex-chart',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/charts/apex-chart.vue'),
        /* no children */
      },
      {
        path: 'chartjs',
        name: 'charts-chartjs',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/charts/chartjs.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/components',
    /* internal name: 'components' */
    /* no component */
    children: [
      {
        path: 'alert',
        name: 'components-alert',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/components/alert.vue'),
        /* no children */
      },
      {
        path: 'avatar',
        name: 'components-avatar',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/components/avatar.vue'),
        /* no children */
      },
      {
        path: 'badge',
        name: 'components-badge',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/components/badge.vue'),
        /* no children */
      },
      {
        path: 'button',
        name: 'components-button',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/components/button.vue'),
        /* no children */
      },
      {
        path: 'chip',
        name: 'components-chip',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/components/chip.vue'),
        /* no children */
      },
      {
        path: 'dialog',
        name: 'components-dialog',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/components/dialog.vue'),
        /* no children */
      },
      {
        path: 'expansion-panel',
        name: 'components-expansion-panel',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/components/expansion-panel.vue'),
        /* no children */
      },
      {
        path: 'list',
        name: 'components-list',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/components/list.vue'),
        /* no children */
      },
      {
        path: 'menu',
        name: 'components-menu',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/components/menu.vue'),
        /* no children */
      },
      {
        path: 'pagination',
        name: 'components-pagination',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/components/pagination.vue'),
        /* no children */
      },
      {
        path: 'progress-circular',
        name: 'components-progress-circular',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/components/progress-circular.vue'),
        /* no children */
      },
      {
        path: 'progress-linear',
        name: 'components-progress-linear',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/components/progress-linear.vue'),
        /* no children */
      },
      {
        path: 'snackbar',
        name: 'components-snackbar',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/components/snackbar.vue'),
        /* no children */
      },
      {
        path: 'tabs',
        name: 'components-tabs',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/components/tabs.vue'),
        /* no children */
      },
      {
        path: 'timeline',
        name: 'components-timeline',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/components/timeline.vue'),
        /* no children */
      },
      {
        path: 'tooltip',
        name: 'components-tooltip',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/components/tooltip.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/dashboards',
    /* internal name: 'dashboards' */
    /* no component */
    children: [
      {
        path: 'analytics',
        name: 'dashboards-analytics',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/dashboards/analytics.vue'),
        /* no children */
      },
      {
        path: 'crm',
        name: 'dashboards-crm',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/dashboards/crm.vue'),
        /* no children */
      },
      {
        path: 'ecommerce',
        name: 'dashboards-ecommerce',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/dashboards/ecommerce.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/extensions',
    /* internal name: 'extensions' */
    /* no component */
    children: [
      {
        path: 'swiper',
        name: 'extensions-swiper',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/extensions/swiper.vue'),
        /* no children */
      },
      {
        path: 'tour',
        name: 'extensions-tour',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/extensions/tour.vue'),
        /* no children */
      }
    ],
  },
  _mergeRouteRecord(
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/forgot-password.vue'),
    /* no children */
  },
  _definePage_default_6
  ),
  {
    path: '/forms',
    /* internal name: 'forms' */
    /* no component */
    children: [
      {
        path: 'autocomplete',
        name: 'forms-autocomplete',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/forms/autocomplete.vue'),
        /* no children */
      },
      {
        path: 'checkbox',
        name: 'forms-checkbox',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/forms/checkbox.vue'),
        /* no children */
      },
      {
        path: 'combobox',
        name: 'forms-combobox',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/forms/combobox.vue'),
        /* no children */
      },
      {
        path: 'custom-input',
        name: 'forms-custom-input',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/forms/custom-input.vue'),
        /* no children */
      },
      {
        path: 'date-time-picker',
        name: 'forms-date-time-picker',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/forms/date-time-picker.vue'),
        /* no children */
      },
      {
        path: 'editors',
        name: 'forms-editors',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/forms/editors.vue'),
        /* no children */
      },
      {
        path: 'file-input',
        name: 'forms-file-input',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/forms/file-input.vue'),
        /* no children */
      },
      {
        path: 'form-layouts',
        name: 'forms-form-layouts',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/forms/form-layouts.vue'),
        /* no children */
      },
      {
        path: 'form-validation',
        name: 'forms-form-validation',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/forms/form-validation.vue'),
        /* no children */
      },
      {
        path: 'form-wizard-icons',
        name: 'forms-form-wizard-icons',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/forms/form-wizard-icons.vue'),
        /* no children */
      },
      {
        path: 'form-wizard-numbered',
        name: 'forms-form-wizard-numbered',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/forms/form-wizard-numbered.vue'),
        /* no children */
      },
      {
        path: 'radio',
        name: 'forms-radio',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/forms/radio.vue'),
        /* no children */
      },
      {
        path: 'range-slider',
        name: 'forms-range-slider',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/forms/range-slider.vue'),
        /* no children */
      },
      {
        path: 'rating',
        name: 'forms-rating',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/forms/rating.vue'),
        /* no children */
      },
      {
        path: 'select',
        name: 'forms-select',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/forms/select.vue'),
        /* no children */
      },
      {
        path: 'slider',
        name: 'forms-slider',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/forms/slider.vue'),
        /* no children */
      },
      {
        path: 'switch',
        name: 'forms-switch',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/forms/switch.vue'),
        /* no children */
      },
      {
        path: 'textarea',
        name: 'forms-textarea',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/forms/textarea.vue'),
        /* no children */
      },
      {
        path: 'textfield',
        name: 'forms-textfield',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/forms/textfield.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/front-pages',
    /* internal name: 'front-pages' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: 'checkout',
        name: 'front-pages-checkout',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/front-pages/checkout.vue'),
        /* no children */
      },
  _definePage_default_7
  ),
      {
        path: 'help-center',
        /* internal name: 'front-pages-help-center' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'front-pages-help-center',
            component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/front-pages/help-center/index.vue'),
            /* no children */
          },
  _definePage_default_8
  ),
          {
            path: 'article',
            /* internal name: 'front-pages-help-center-article' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: ':title',
                name: 'front-pages-help-center-article-title',
                component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/front-pages/help-center/article/[title].vue'),
                /* no children */
              },
  _definePage_default_9
  )
            ],
          }
        ],
      },
      {
        path: 'landing-page',
        /* internal name: 'front-pages-landing-page' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'front-pages-landing-page',
            component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/front-pages/landing-page/index.vue'),
            /* no children */
          },
  _definePage_default_10
  )
        ],
      },
  _mergeRouteRecord(
      {
        path: 'payment',
        name: 'front-pages-payment',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/front-pages/payment.vue'),
        /* no children */
      },
  _definePage_default_11
  ),
  _mergeRouteRecord(
      {
        path: 'pricing',
        name: 'front-pages-pricing',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/front-pages/pricing.vue'),
        /* no children */
      },
  _definePage_default_12
  )
    ],
  },
  _mergeRouteRecord(
  {
    path: '/login',
    name: 'login',
    component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/login.vue'),
    /* no children */
  },
  _definePage_default_13
  ),
  _mergeRouteRecord(
  {
    path: '/not-authorized',
    name: 'not-authorized',
    component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/not-authorized.vue'),
    /* no children */
  },
  _definePage_default_14
  ),
  {
    path: '/pages',
    /* internal name: 'pages' */
    /* no component */
    children: [
      {
        path: 'account-settings',
        /* internal name: 'pages-account-settings' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: ':tab',
            name: 'pages-account-settings-tab',
            component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/pages/account-settings/[tab].vue'),
            /* no children */
          },
  _definePage_default_15
  )
        ],
      },
      {
        path: 'authentication',
        /* internal name: 'pages-authentication' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: 'forgot-password-v1',
            name: 'pages-authentication-forgot-password-v1',
            component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/pages/authentication/forgot-password-v1.vue'),
            /* no children */
          },
  _definePage_default_16
  ),
  _mergeRouteRecord(
          {
            path: 'forgot-password-v2',
            name: 'pages-authentication-forgot-password-v2',
            component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/pages/authentication/forgot-password-v2.vue'),
            /* no children */
          },
  _definePage_default_17
  ),
  _mergeRouteRecord(
          {
            path: 'login-v1',
            name: 'pages-authentication-login-v1',
            component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/pages/authentication/login-v1.vue'),
            /* no children */
          },
  _definePage_default_18
  ),
  _mergeRouteRecord(
          {
            path: 'login-v2',
            name: 'pages-authentication-login-v2',
            component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/pages/authentication/login-v2.vue'),
            /* no children */
          },
  _definePage_default_19
  ),
  _mergeRouteRecord(
          {
            path: 'register-multi-steps',
            name: 'pages-authentication-register-multi-steps',
            component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/pages/authentication/register-multi-steps.vue'),
            /* no children */
          },
  _definePage_default_20
  ),
  _mergeRouteRecord(
          {
            path: 'register-v1',
            name: 'pages-authentication-register-v1',
            component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/pages/authentication/register-v1.vue'),
            /* no children */
          },
  _definePage_default_21
  ),
  _mergeRouteRecord(
          {
            path: 'register-v2',
            name: 'pages-authentication-register-v2',
            component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/pages/authentication/register-v2.vue'),
            /* no children */
          },
  _definePage_default_22
  ),
  _mergeRouteRecord(
          {
            path: 'reset-password-v1',
            name: 'pages-authentication-reset-password-v1',
            component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/pages/authentication/reset-password-v1.vue'),
            /* no children */
          },
  _definePage_default_23
  ),
  _mergeRouteRecord(
          {
            path: 'reset-password-v2',
            name: 'pages-authentication-reset-password-v2',
            component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/pages/authentication/reset-password-v2.vue'),
            /* no children */
          },
  _definePage_default_24
  ),
  _mergeRouteRecord(
          {
            path: 'two-steps-v1',
            name: 'pages-authentication-two-steps-v1',
            component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/pages/authentication/two-steps-v1.vue'),
            /* no children */
          },
  _definePage_default_25
  ),
  _mergeRouteRecord(
          {
            path: 'two-steps-v2',
            name: 'pages-authentication-two-steps-v2',
            component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/pages/authentication/two-steps-v2.vue'),
            /* no children */
          },
  _definePage_default_26
  ),
  _mergeRouteRecord(
          {
            path: 'verify-email-v1',
            name: 'pages-authentication-verify-email-v1',
            component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/pages/authentication/verify-email-v1.vue'),
            /* no children */
          },
  _definePage_default_27
  ),
  _mergeRouteRecord(
          {
            path: 'verify-email-v2',
            name: 'pages-authentication-verify-email-v2',
            component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/pages/authentication/verify-email-v2.vue'),
            /* no children */
          },
  _definePage_default_28
  )
        ],
      },
      {
        path: 'cards',
        /* internal name: 'pages-cards' */
        /* no component */
        children: [
          {
            path: 'card-actions',
            name: 'pages-cards-card-actions',
            component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/pages/cards/card-actions.vue'),
            /* no children */
          },
          {
            path: 'card-advance',
            name: 'pages-cards-card-advance',
            component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/pages/cards/card-advance.vue'),
            /* no children */
          },
          {
            path: 'card-basic',
            name: 'pages-cards-card-basic',
            component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/pages/cards/card-basic.vue'),
            /* no children */
          },
          {
            path: 'card-gamification',
            name: 'pages-cards-card-gamification',
            component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/pages/cards/card-gamification.vue'),
            /* no children */
          },
          {
            path: 'card-statistics',
            name: 'pages-cards-card-statistics',
            component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/pages/cards/card-statistics.vue'),
            /* no children */
          },
          {
            path: 'card-widgets',
            name: 'pages-cards-card-widgets',
            component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/pages/cards/card-widgets.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'dialog-examples',
        /* internal name: 'pages-dialog-examples' */
        /* no component */
        children: [
          {
            path: '',
            name: 'pages-dialog-examples',
            component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/pages/dialog-examples/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'faq',
        name: 'pages-faq',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/pages/faq.vue'),
        /* no children */
      },
      {
        path: 'icons',
        name: 'pages-icons',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/pages/icons.vue'),
        /* no children */
      },
      {
        path: 'misc',
        /* internal name: 'pages-misc' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: 'coming-soon',
            name: 'pages-misc-coming-soon',
            component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/pages/misc/coming-soon.vue'),
            /* no children */
          },
  _definePage_default_29
  ),
  _mergeRouteRecord(
          {
            path: 'under-maintenance',
            name: 'pages-misc-under-maintenance',
            component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/pages/misc/under-maintenance.vue'),
            /* no children */
          },
  _definePage_default_30
  )
        ],
      },
      {
        path: 'pricing',
        name: 'pages-pricing',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/pages/pricing.vue'),
        /* no children */
      },
      {
        path: 'typography',
        name: 'pages-typography',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/pages/typography.vue'),
        /* no children */
      },
      {
        path: 'user-profile',
        /* internal name: 'pages-user-profile' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: ':tab',
            name: 'pages-user-profile-tab',
            component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/pages/user-profile/[tab].vue'),
            /* no children */
          },
  _definePage_default_31
  )
        ],
      }
    ],
  },
  _mergeRouteRecord(
  {
    path: '/register',
    name: 'register',
    component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/register.vue'),
    /* no children */
  },
  _definePage_default_32
  ),
  _mergeRouteRecord(
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/reset-password.vue'),
    /* no children */
  },
  _definePage_default_33
  ),
  {
    path: '/tables',
    /* internal name: 'tables' */
    /* no component */
    children: [
      {
        path: 'data-table',
        name: 'tables-data-table',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/tables/data-table.vue'),
        /* no children */
      },
      {
        path: 'simple-table',
        name: 'tables-simple-table',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/tables/simple-table.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/wizard-examples',
    /* internal name: 'wizard-examples' */
    /* no component */
    children: [
      {
        path: 'checkout',
        name: 'wizard-examples-checkout',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/wizard-examples/checkout.vue'),
        /* no children */
      },
      {
        path: 'create-deal',
        name: 'wizard-examples-create-deal',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/wizard-examples/create-deal.vue'),
        /* no children */
      },
      {
        path: 'property-listing',
        name: 'wizard-examples-property-listing',
        component: () => import('C:/Users/Usuario/Documents/GitHub/catona/src/pages/wizard-examples/property-listing.vue'),
        /* no children */
      }
    ],
  }
]
